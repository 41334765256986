<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <div class="relative width_100_percent flex flex_wrap align_center space_between table_header_container">
            <div class="flex flex_wrap align_center left">
                <div class="flex align_center justify_center item" :class="{ current: goodStatus == goodStatusDef.all }" @click="onChangeMenu(goodStatusDef.all)">全部({{ totalCount }})</div>
                <div class="flex align_center justify_center item" :class="{ current: goodStatus == goodStatusDef.ing }" @click="onChangeMenu(goodStatusDef.ing)">出售中({{ OnSaleCount }})</div>
                <div class="flex align_center justify_center item" :class="{ current: goodStatus == goodStatusDef.ed }" @click="onChangeMenu(goodStatusDef.ed)">已售完({{ SoldOutCount }})</div>
                <div class="flex align_center justify_center item" :class="{ current: goodStatus == goodStatusDef.off }" @click="onChangeMenu(goodStatusDef.off)">已下架({{ StopCount }})</div>
            </div>
            <div class="flex flex_wrap align_center right">
                <Button type="primary" @click="onEdit()">添加商品</Button>
                <Button type="success" @click="onBatchUpOff(1)">上架</Button>
                <Button type="warning" @click="onBatchUpOff(0)">下架</Button>
                <Button type="info" @click="onBatchUpdateType">修改分类</Button>
            </div>
        </div>
        <Card class="mt_20 table_container" ref="table_container">
            <Table stripe class="table" :columns="dataColumn" :data="dataList" @on-selection-change="onSelectionChange">
                <template slot-scope="{ row }" slot="thumbnail">
                    <previewImage :thumWidth="60" :thumHeight="60" :src="row.thumbnail"></previewImage>
                </template>
                <template slot-scope="{ row }" slot="classType">{{ getClassTypeName(row.classList) }}</template>
                <template slot-scope="{ row }" slot="nowState">
                    <span :class="'good_state_' + row.nowState">{{ { 1: '出售中', 2: '已售完', 3: '已下架' }[row.nowState] }}</span>
                </template>
                <template slot-scope="{ row }" slot="path">
                    {{ '/pages/goodsDetail/index?gid=' + row.id }}
                </template>
                <template slot-scope="{ row }" slot="ctime">
                    {{ new Date(row.ctime * 1000).Format('yyyy-MM-dd HH:mm:ss') }}
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="success" size="small" ghost @click="onCopy(row)">复制</Button>
                        <Button v-if="row.nowState != 3" type="warning" size="small" ghost @click="onOff(row)">下架</Button>
                        <!-- <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button> -->
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>

        <Modal :closable="false" v-model="showBatchUpdateGoodType" title="批量修改商品分类" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">选择类别</div>
                <Cascader :transfer="true" :data="classTypeList" trigger="hover" v-model="formData.goodClass" placeholder="请选择类别"></Cascader>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelUpdate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmUpdate">确定</Button>
            </div>
        </Modal>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import { getParamType, type_array, type_null } from '@/lib/util';
import { getClassListData } from './common';
import { reqGoodsList, reqGoodsSaveStatus, reqGoodsUpdateClass } from '@/lib/request/auth2';

const goodStatusDef = {
    all: '0',
    ing: '1',
    ed: '2',
    off: '3',
};

export default {
    name: 'goodList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        this.goodStatusDef = goodStatusDef;
        return {
            searchs: [
                { id: hm.createId(), label: '商品分类', placeholder: '请选择分类', type: 'cascader', bind: 'classId', list: [] },
                // { id: hm.createId(), label: '最低价格', placeholder: '输入最低价格', type: 'input', bind: 'minPrice' },
                // { id: hm.createId(), label: '最高价格', placeholder: '输入最高价格', type: 'input', bind: 'maxPrice' },
                // { id: hm.createId(), label: '发布日期', placeholder: '选择日期', type: 'daterange', bind: 'date', onChange: this.onChangeDateRange },
                { id: hm.createId(), label: '关键字', placeholder: '输入关键字', type: 'input', bind: 'title' },
            ],
            dataColumn: [
                { type: 'selection', width: 60, align: 'center' },
                { key: 'id', title: '商品id', minWidth: 120 },
                { slot: 'thumbnail', title: '缩略图', minWidth: 80 },
                { key: 'title', title: '商品名称', minWidth: 120, tooltip: true },
                { slot: 'classType', title: '分类', minWidth: 120 },
                { slot: 'nowState', title: '状态', minWidth: 100 },
                { key: 'num', title: '库存', minWidth: 80 },
                { key: 'gain_num', title: '销量', minWidth: 80 },
                { slot: 'path', title: 'path', minWidth: 200 },
                { slot: 'ctime', title: '创建时间', minWidth: 200 },
                { slot: 'manager', title: '操作', minWidth: 140 },
            ],
            totalCount: 0,
            OnSaleCount: 0,
            SoldOutCount: 0,
            StopCount: 0,
            dataList: [],
            classTypeList: [],
            goodStatus: goodStatusDef.all,
            checkedGoodIds: [],
            showBatchUpdateGoodType: false,
            formData: {
                goodClass: null,
            },
        };
    },
    mounted() {
        this.getClassTypeList();
        this.getList();
    },
    methods: {
        getClassTypeName(classList = {}) {
            return classList.title + '-' + (classList.children || {}).title;
        },
        onChangeDateRange(evt) {
            this.params.startDate = evt[0] || null;
            this.params.endDate = evt[1] || null;
        },
        //获取分类列表数据
        getClassTypeList() {
            getClassListData().then(list => {
                let classTypeList = list;
                this.classTypeList = classTypeList;
                this.searchs[0].list = classTypeList;
            });
        },
        onChangeMenu(nowState) {
            if (this.goodStatus != nowState) {
                this.goodStatus = nowState;

                this.params.nowState = nowState;
                this.params.pageNums = 1;
                this.getList();
            }
        },
        //获取列表数据
        getList() {
            // console.log(JSON.stringify(this.params, null, '\t'));
            this.showAjaxLoading();
            let params = window.structuredClone(this.params);
            if ((params.classId || []).length > 0) {
                params.cid = params.classId[1];
                delete params.classId;
            }
            reqGoodsList(params)
                .then(res => {
                    let { totalCount, OnSaleCount, SoldOutCount, StopCount } = res?.data || {};
                    this.totalCount = totalCount;
                    this.OnSaleCount = OnSaleCount;
                    this.SoldOutCount = SoldOutCount;
                    this.StopCount = StopCount;
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_row) {
            let query = {};
            let id = (_row || {}).id || null;
            if (!!id) {
                query = { id };
            }
            this.goUrl('/goodAdd', query);
        },
        //复制
        onCopy(_row = {}) {
            let query = {};
            let id = (_row || {}).id || null;
            if (!!id) {
                query = { id, copy: 1 };
            }
            this.goUrl('/goodAdd', query);
        },
        //下架
        onOff(info = {}) {
            let { id, title } = info || {};
            this.confirmDialog(`确认下架【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqGoodsSaveStatus({
                        status: 0,
                        id: JSON.stringify([id]),
                    })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(res => {
                            this.fadeWarning(res.errmsg);
                        });
                }
            });
        },
        //删除
        onDelete(info = {}) {
            let { id, title } = info || {};
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    //
                }
            });
        },
        //批量上/下架
        onBatchUpOff(isUp) {
            let managerTitle = `${isUp == 1 ? '上架' : '下架'}`;
            if (this.checkedGoodIds.length <= 0) {
                this.fadeWarning(`${managerTitle}前，请先选择商品`);
                return;
            }

            this.confirmDialog(`确定${managerTitle}这批商品吗？`).then(isOK => {
                console.log(JSON.stringify(this.checkedGoodIds, null, '\t'));
                if (isOK == 1) {
                    reqGoodsSaveStatus({
                        status: isUp,
                        id: JSON.stringify(this.checkedGoodIds),
                    })
                        .then(res => {
                            this.fadeAlert(`${managerTitle}成功`);
                            this.getList();
                        })
                        .catch(res => {
                            this.fadeWarning(res.errmsg);
                        });
                }
            });
        },
        //批量修改分类
        onBatchUpdateType() {
            let managerTitle = '修改分类';
            if (this.checkedGoodIds.length <= 0) {
                this.fadeWarning(`${managerTitle}前，请先选择商品`);
                return;
            }

            this.showBatchUpdateGoodType = true;
            this.formData.goodClass = null;
        },
        onCancelUpdate() {
            this.showBatchUpdateGoodType = false;
            window.setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        onConfirmUpdate() {
            this.showAjaxLoading();

            let { goodClass } = this.formData || {};
            let goodClass_type = getParamType(goodClass);
            if (goodClass_type == type_null || (goodClass_type == type_array && goodClass.length <= 0)) {
                this.fadeWarning('请选择类别');
                this.hideAjaxLoading();
                return;
            }
            let cid = goodClass.slice(-1)[0];

            reqGoodsUpdateClass({
                cid,
                id: JSON.stringify(this.checkedGoodIds),
            })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.getList();
                    this.onCancelUpdate();
                })
                .catch(res => {
                    this.fadeWarning(res.errmsg);
                });
        },
        onSelectionChange(evt) {
            this.checkedGoodIds = evt.map(info => info.id);
        },
    },
};
</script>
<style lang="less" scoped>
.good_state_1 {
    color: green;
}
.good_state_2 {
    color: red;
}
.good_state_1 {
    color: blue;
}
.form_label {
    width: 100px;
}
.table_header_container {
    gap: 10px 0;
    .left {
        gap: 10px;
        .item {
            position: relative;
            width: 120px;
            height: 35px;
            color: gray;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: blue;
                transform: scaleX(0);
                transition: transform 0.3s;
            }
            &.current {
                color: blue;
                &::after {
                    transform: scaleY(1);
                }
            }
        }
    }
    .right {
        gap: 10px;
    }
}
</style>
